/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer";
import countries from "./countries";
import GT4Init from "../../utils/gt4";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const ContactUsPage = () => {
  const myRef = useRef(null);
  const [searchParams] = useSearchParams();
  const getParams = searchParams.get("tab");
  const [tab, setTab] = useState("contact");
  useEffect(() => {
    if (getParams) {
      setTab(getParams);
    }
  }, [getParams]);
  const handleTab = (value) => {
    setTab(value);
  };
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = () => scrollToRef(myRef);
  const navigate = useNavigate();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    enquiry: "Inquiry",
    country: "",
    companyName: "",
    message: "",
  };
  const [values, setValues] = useState(initialValues);
  const [error, setErorr] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const btnRef = useRef(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captcha) {
      setErorr(true);
    } else {
      await emailjs
        .sendForm(
          "service_iy7jqxx",
          "template_tmmpn4i",
          e.currentTarget,
          "d-FmGyQxhUeJHDfm6"
        )
        .then(
          (result) => {
            navigate("/thank-you", { replace: true });
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  async function handlerForBind(c) {
    c.appendTo("#captchaBox");

    c.onSuccess(async () => {
      setCaptcha(true);
      setErorr(false);
      console.log("success");
    });

    c.onError((err) => {
      console.log("GT error: ", err);
      c.reset();
    });
    c.onClose(() => {
      // console.log("GT close: ");

      c.reset();
    });
    c.onFail((err) => {
      // console.log("GT fail: ", err);
      console.log("Captcha Failed!", err);

      c.reset();
    });
  }
  useEffect(() => {
    if (tab === "prospective-client") {
      GT4Init();

      initGeetest4(
        {
          captchaId: "6e54965a7b9c5deec44de2903a508d52",
          product: "popup",
          language: "eng",
          protocol: "https://",
          onError: (err) => console.log(err),
        },
        handlerForBind
      );
    }
  }, [tab]);

  return (
    <>
      <div className="contact-page" ref={myRef}>
        <div className="page__header-intro">
          <Header />
        </div>
        <div className="page__heading">Contact Us</div>
        <div className="tab__container">
          <div className="tab__links">
            <div className="tab__link">
              <button
                className={`tab__btn ${tab === "contact" ? "active" : ""}`}
                onClick={() => handleTab("contact")}
              >
                Contact
              </button>
            </div>
            <div className="tab__link">
              <button
                className={`tab__btn ${
                  tab === "prospective-client" ? "active" : ""
                }`}
                onClick={() => handleTab("prospective-client")}
              >
                Prospective Client
              </button>
            </div>
          </div>
          <div className="tab__contents">
            {tab === "contact" && (
              <div className="tab__content">
                <div className="referrence__content">
                  <div className="content__title">Client relations</div>
                  <a
                    className="content__descr"
                    href="mailto:client@cobancapital.com"
                  >
                    client@cobancapital.com
                  </a>
                </div>
                <div className="referrence__content">
                  <div className="content__title">Investor relations</div>
                  <a
                    className="content__descr"
                    href="mailto:ir@cobancapital.com"
                  >
                    ir@cobancapital.com
                  </a>
                </div>
                <div className="referrence__content">
                  <div className="content__title">General Inqueries</div>
                  <a
                    className="content__descr"
                    href="mailto:info@cobancapital.com"
                  >
                    info@cobancapital.com
                  </a>
                </div>
                <div className="referrence__content">
                  <div className="content__title">Press Inqueries</div>
                  <a
                    className="content__descr"
                    href="mailto:press@cobancapital.com"
                  >
                    press@cobancapital.com
                  </a>
                </div>
                <div className="page__content">
                  <div className="page__questions_1">
                    For regulatory disclosures, please kindly write us using the
                    General Inqueries details above.
                  </div>
                  <div className="page__questions">
                    *Please use{" "}
                    <a
                      style={{ fontSize: "15px", display: "inline-block" }}
                      href="/official-verification"
                    >
                      Coban Capital Verify
                    </a>{" "}
                    to check whether the source officially represents Coban
                    Capital.
                  </div>
                </div>
              </div>
            )}
            {tab === "prospective-client" && (
              <div className="tab__content">
                <form className="contact__form" onSubmit={handleSubmit}>
                  <div className="form__container">
                    <div
                      className="form__control w-half"
                      style={{ paddingRight: "1rem" }}
                    >
                      <label htmlFor="firstName">First Name</label>
                      <input
                        id="firstName"
                        required
                        name="firstName"
                        type="text"
                        value={values.firstName}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                    <div className="form__control w-half">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        id="lastName"
                        required
                        name="lastName"
                        type="text"
                        value={values.lastName}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form__container">
                    <div
                      className="form__control w-half"
                      style={{ paddingRight: "1rem" }}
                    >
                      <label htmlFor="country">Country</label>
                      <select
                        id="country"
                        name="country"
                        required
                        value={values.country}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="">Please select a country</option>
                        {countries.map((country) => {
                          return (
                            <option key={country} value={country}>
                              {country}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form__control w-half">
                      <label htmlFor="companyName">Company Name</label>
                      <input
                        id="companyName"
                        required
                        name="companyName"
                        type="text"
                        value={values.companyName}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form__container">
                    <div
                      className="form__control w-half"
                      style={{ paddingRight: "1rem" }}
                    >
                      <label htmlFor="email">E-mail</label>
                      <input
                        id="email"
                        required
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                    <div className="form__control w-half">
                      <label htmlFor="enquiry">Inquiry type</label>
                      <select
                        id="enquiry"
                        name="enquiry"
                        value={values.enquiry}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="Inquiry">Inquiry</option>
                        <option value="Request factsheet">
                          Request factsheet
                        </option>
                        <option value="Prospectus">Prospectus</option>
                        <option value="Careers">Careers</option>
                        <option value="Press">Press</option>
                      </select>
                    </div>
                  </div>

                  <div className="form__control">
                    <label htmlFor="message">Message</label>
                    <textarea
                      id="message"
                      name="message"
                      rows="8"
                      cols="20"
                      required
                      value={values.message}
                      onChange={(e) => handleInputChange(e)}
                    ></textarea>
                  </div>

                  {/* <input
                    type="hidden"
                    name="_confirmation"
                    value="Thank you , your inquiry has been submitted"
                  /> */}

                  <div id="captchaBox"></div>
                  {error && <div className="error">Please verify captcha!</div>}

                  <div>
                    <button type="submit" ref={btnRef} className="submit__btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer onClick={executeScroll} />
    </>
  );
};

export default ContactUsPage;
