import React, { useRef } from "react";
import Footer from "./Footer";
import Header from "./Header/Header";

const Layout = ({ children }) => {
  const myRef = useRef(null);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = () => scrollToRef(myRef);
  return (
    <div ref={myRef}>
      <Header />
      {children}
      <Footer onClick={executeScroll} />
    </div>
  );
};

export default Layout;
