import React from "react";
import Layout from "../../Components/Layout";

const TermsUse = () => {
  return (
    <Layout>
      <div className="site-policy">
        <div className="policy__heading">Terms of Use</div>
        <div className="policy__sub-heading">
          Last updated: October 22, 2021
        </div>
        <div className="policy__description">
          AS USED HEREIN, THE TERMS “YOU” AND “USER” SHALL MEAN ANY VISITOR TO
          THE WEBSITE.
        </div>
        <div className="policy__description">
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY ACCESSING THIS
          WEBSITE YOU AGREE TO ABIDE BY THE TERMS AND CONDITIONS BELOW. These
          Terms of Use govern your use of and interaction with the Website.
          COBAN CAPITAL , reserve the right, in its sole discretion, to change,
          modify, add or remove any portion of this Website, including the Terms
          and condition, in whole or in part, at any time, with or without
          notice to you. WE WILL NOTIFY YOU OF AMENDMENTS TO THESE TERMS AND
          CONDITIONS BY POSTING THEM TO THIS WEBSITE. IF YOU DO NOT ABIDE BY
          THESE TERMS AND CONDITIONS, PLEASE DO NOT ACCESS THIS WEBSITE.
        </div>
        <div className="policy__description">
          If your use of this Website gives rise to any third party claim
          against you or any Coban Capital Party, you agree to (i) indemnify and
          hold harmless the Coban Capital Parties with respect to any such
          claim, (ii) release the Coban Capital Parties from any such claim and
          (iii) waive all laws that may limit the efficacy of such release.
        </div>
        <div className="policy__description">
          If any part of the Terms of Use is unlawful, void or unenforceable,
          that part will be deemed severable and will not affect the validity
          and enforceability of any remaining provisions. The Terms of Use,
          together with the Privacy Policy, constitute the entire agreement
          between you and Coban Capital relating to your use of the Site.
          References to “Coban Capital,” "we," "us," and "our" are references to
          Coban Capital.
        </div>
      </div>
    </Layout>
  );
};

export default TermsUse;
