import React from "react";
import Layout from "../../Components/Layout";

const CookiesPolicy = () => {
  return (
    <Layout>
      <div className="site-policy">
        <div className="policy__heading">Cookies Policy</div>
        <div className="policy__sub-heading">Updated October 22,2021</div>

        <div className="policy__sub-heading">Additional information</div>
        <div className="policy__description">
          This website makes use of cookies, which are little files transferred
          to your computer or other device to improve your browsing experience.
          Cookies allow a website or service to recognise whether or not your
          computer or device has visited it before. Cookies can then be used to
          better understand how a site or service is used, to make moving
          between pages easier, to remember your preferences, and to enhance
          your overall browsing experience. Cookies can also help make online
          advertising more relevant to you and your choices by storing
          information about your preferences.{" "}
        </div>
        <div className="policy__description">
          {" "}
          Cookies do not generally contain any information that directly
          identifies a user, but the information stored in and obtained through
          cookies may be linked to personal information about you that we keep
          on file.A persistent cookie is saved by a web browser and will remain
          valid unless the user deletes it before the expiration date; a session
          cookie will expire when the user closes the web browser at the end of
          the user session.
        </div>
        <div className="policy__description">
          This page describes what data cookies capture, how we use that data,
          and why we might need to keep these cookies in the future. We'll also
          show you how to prevent these cookies from being saved; but, blocking
          cookies may cause some aspects of the site's operation to be degraded
          or 'broken'.
        </div>
        <div className="policy__sub-heading">Types of cookies we use:</div>
        <div className="policy__description">
          The most common types of cookies we utilise are "performance" and
          "functionality" cookies. Following is a quick description of each
          cookie kind.
        </div>

        <div className="policy__sub-heading">
          1. Performance-enhancing cookies.{" "}
        </div>
        <div className="policy__description">
          These cookies save information about how you use the site, such as the
          unique username you provided. These cookies may also be used to
          identify you as a logged-in user so that we can deliver more recent
          content to you than if you had never logged in. Cookies are also used
          to track overall website usage as well as to test new features and
          changes. The information received is used to improve the functionality
          of the website.
        </div>

        <div className="policy__sub-heading">2. Functionality cookies. </div>
        <div className="policy__description">
          These cookies enable us to remember how you log in, if you've opted to
          cease viewing advertisements, whether you edited an article on the
          website while logged out, when you logged in or out, and the state or
          history of website tools you've used. These cookies also allow us to
          customise the website to provide you with more features and material,
          as well as remember how you've changed the website in other ways, such
          as customising the toolbars we provide in the right column of every
          page. These cookies may collect anonymous information but are not used
          to track your activities on other websites or services.
        </div>

        <div className="policy__sub-heading">3. Disabling cookies.</div>
        <div className="policy__description">
          You can prevent cookies from being set by changing your browser's
          settings (see your browser Help for how to do this). The functioning
          of this and many other websites will be impacted if cookies are
          disabled. When cookies are disabled, the site's functionality and
          features are frequently disabled as well.
        </div>

        <div className="policy__sub-heading">4. Third party cookies</div>
        <div className="policy__description">
          In some cases, we may additionally use cookies provided by trustworthy
          third parties. The third-party cookies you may encounter when surfing
          this site are explained in the section below.
        </div>
        <div className="policy__description">
          This site uses Google Analytics, one of the most extensively used and
          trusted analytics platforms on the web, to help us better understand
          how you use the site and how we can improve it. These cookies may
          record information such as how long you spend on the site and the
          pages you visit so that we can continue to provide you with engaging
          content.
        </div>

        <div className="policy__sub-heading">MORE INFORMATION</div>
        <div className="policy__description">
          For more information please contact us through our preferred contact
          method.
        </div>

        <a
          href="mailto:info@cobancapital.com"
          className="policy__description email--link"
        >
          Email: info@cobancapital.com
        </a>
      </div>
    </Layout>
  );
};

export default CookiesPolicy;
