import React, { useState } from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import logo from "../../assets/logo.png";
import menu from "../../assets/menu.svg";
import AppButton from "../../common/AppButton";

const MobileHeader = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  return (
    <>
      <div className="mobile-header">
        <Link
          to="/"
          className="header_name"
          onClick={() => setIsOpenMenu(false)}
        >
          <img src={logo} className="header-logo" alt="header logo" />
        </Link>
        <Link className="text-decoration-none mx--10" to="/login">
          <AppButton title="Investors Login" />
        </Link>
        <img
          src={menu}
          className="header-hamberg"
          onClick={() => setIsOpenMenu(!isOpenMenu)}
          alt="hamberg"
        />
      </div>
      <Menu active={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
    </>
  );
};

export default MobileHeader;
