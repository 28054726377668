import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import AppButton from "../../common/AppButton";

const DesktopHeader = () => {
  return (
    <div className="header">
      <Link to="/" style={{ textDecoration: "none" }}>
        <div className="header__left">
          <img className="left__logo" src={logo} alt="coban capital" />
        </div>
      </Link>
      <div className="header__right">
        <Link to="/" className={`right__text`}>
          Home
        </Link>
        <Link to="/career" className={`right__text`}>
          Careers <sup style={{ color: "green" }}>+1</sup>
        </Link>
        <Link to="/sustainability" className={`right__text`}>
          Sustainability
        </Link>
        <Link to="/news-and-insights" className={`right__text`}>
          News and Insights <sup className="new">New</sup>
        </Link>
        <Link to="/contact-us" className={` right__text border-right-gray`}>
          Contact Us
        </Link>
        <div className="right__mint">
          <Link className="text-decoration-none" to="/login">
            <AppButton title="Investors Login" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DesktopHeader;
