import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

const Footer = ({ onClick }) => {
  const pathname = window.location.pathname;
  const checkPath =
    pathname === "/login"
      ? true
      : pathname === "/reset-password"
      ? true
      : false;
  return (
    <>
      {!checkPath && (
        <div className="footer" key="demo6">
          <div className="footer__left">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img className="left__logo" src={logo} alt="coban capital" />
            </Link>
            <a
              href="https://www.unpri.org/"
              target="_blank"
              style={{
                textDecoration: "none",
                position: "relative",
                paddingBottom: "80px",
              }}
              rel="noreferrer"
            >
              <img
                className="bottom__logo"
                src="/pri-res-invest.png"
                alt="pri"
                style={{ objectFit: "contain" }}
              />
            </a>
          </div>
          <div className="footer__right">
            <div className="right__content">
              <div className="content__title">Contact</div>
              <Link
                to="/contact-us"
                onClick={onClick}
                className="content__link text-decoration-none"
              >
                Contact Us
              </Link>
              <Link
                to="/pgp-communiation"
                onClick={onClick}
                className="content__link text-decoration-none"
              >
                PGP Communication
              </Link>
              <Link
                to="/official-verification"
                onClick={onClick}
                className="content__link text-decoration-none"
              >
                Official Verification
              </Link>
            </div>
            <div className="right__content">
              <div className="content__title">About</div>
              <Link
                to="/#about"
                className="content__link text-decoration-none "
                onClick={onClick}
              >
                About Us
              </Link>
              <Link
                to="/career"
                onClick={onClick}
                className="content__link text-decoration-none "
              >
                Careers <sup style={{ color: "green" }}>+1</sup>
              </Link>
              <Link
                to="/sustainability"
                onClick={onClick}
                className="content__link text-decoration-none "
              >
                Sustainability
              </Link>
              <Link
                to="/news-and-insights"
                onClick={onClick}
                className="content__link text-decoration-none"
                style={{ position: "relative" }}
              >
                News and Insights{" "}
                <sup
                  style={{
                    color: "green",
                    position: "absolute",
                  }}
                >
                  +2
                </sup>
              </Link>
            </div>
            <div className="right__content">
              <div className="content__title">Conditions</div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Link
                  to="/cookies-policy"
                  onClick={onClick}
                  className="content__link text-decoration-none"
                >
                  Cookies Policy
                </Link>
                <Link
                  to="/terms-use"
                  onClick={onClick}
                  className="content__link text-decoration-none"
                >
                  Terms of Use
                </Link>
                <Link
                  to="/privacy-policy"
                  onClick={onClick}
                  className="content__link text-decoration-none"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/disclosure"
                  onClick={onClick}
                  className="content__link text-decoration-none"
                >
                  Disclosures
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={
          checkPath
            ? `footer__copy-right position__fixed`
            : `footer__copy-right`
        }
        key="demo7"
      >
        ©Coban Capital 2020 | All rights reserved.{" "}
      </div>
    </>
  );
};

export default Footer;
