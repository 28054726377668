import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import AppButton from "../common/AppButton";
import { useCookies } from "react-cookie";
const BuyTransferModal = () => {
  // const { pathname } = useLocation();
  const [cookies, setCookie] = useCookies(["cookie"]);
  const [isOpen, setIsOpen] = useState(cookies.cookie ? cookies.cookie : false);
  const toggle = () => {
    setCookie("cookie", !isOpen, {
      path: "/",
    });
    setIsOpen(!isOpen);
  };
  // useEffect(() => {
  //   if (!isOpen) {
  //     toggle();
  //   }
  // });
  return (
    <Modal
      isOpen={!isOpen}
      onRequestClose={toggle}
      overlayClassName="cookies-modal-overlay"
      className="cookies-modal"
      ariaHideApp={false}
    >
      <div className="cookies-content ">
        <div className="content__description">
          This website uses cookies and other tools to help offer you a better
          experience on our website. <br />
          By continuing to use this website, you Accept the use of cookies. To
          view the policy, please{" "}
          <Link to="/cookies-policy" className="description__underline">
            click here
          </Link>
        </div>
        <div className="content--action">
          <AppButton title="I Accept" onClick={toggle} />
        </div>
      </div>
    </Modal>
  );
};
export default BuyTransferModal;
