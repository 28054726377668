import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout";

const TaxStrategy = () => {
  return (
    <Layout>
      <div className="site-policy">
        <div className="policy__heading">Uk Tax strategy</div>
        <div className="policy__description" style={{ padding: "3rem" }}>
          <p>
            “Coban Group” is a global, multi-asset investment management Firm
            with its headquarters in London, United Kingdom. This tax related
            strategy sets out Coban Group’s approach to UK tax, relating to each
            UK entity in the Coban Group , Coban Capital Asset Management LLP
            and Coban Global Limited (each a “Coban UK entity” and, together,
            “Coban Capital UK”).
          </p>
          <p>
            <b>Approach to risk management and governance arrangements</b>
          </p>
          <p>
            Coban Capital UK is committed to ensuring a transparent tax and risk
            management in all its undertakings. This is undersign by the
            respectful governing entities of each of Coban Capital UK.
          </p>

          <p>
            <b>Attitude to UK tax planning and management of tax risks</b>
          </p>
          <p>
            Coban Capital UK takes advantage of the various tax reliefs while
            mitigating both our financial risk and tax risks.Coban Capital UK
            engages external Tax experts for advisory where tax treatments under
            a given jurisdiction’s laws are complex
          </p>
          <p>
            <b>Approach towards dealings with HMRC</b>
          </p>
          <p>
            Coban Capital UK ensures that it responds to queries raised by HMRC
            in a timely and orderly manner while continuing to build an open ,
            honest and long lasting trustful relationship.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TaxStrategy;
