import React, { useRef } from "react";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer";
import articleImg from "../../../assets/collab.jpg";
import { Link } from "react-router-dom";

const CollaborationAnnouncementPage = () => {
  const myRef = useRef(null);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = () => scrollToRef(myRef);
  return (
    <>
      <div className="newsInsight-page" ref={myRef}>
        <div className="page__header-intro">
          <Header />
        </div>
        <div className="page__heading">News and Insights</div>
        <div className="blog__container">
          <div className="blog__filter"></div>

          <div className="post">
            <div className="post__title">
              In this press release , Coban Capital today announced the
              collaboration with the management team of Dr. H.C. Peter Kudera.
              Mr.Mbai, Chairman and CEO of Coban Capital said :
            </div>
            <div className="post__img">
              <img src={articleImg} alt="article" />
            </div>
            <div className="post__desc">
              <p>
                Coban Capital today announced the collaboration with the
                management team of Dr. H.C. Peter Kudera. Mr.Mbai, Chairman and
                CEO of Coban Capital said :
              </p>
              <p>
                "We are very glad to have signed an agreement, on an ongoing
                investment journey with Family Offices, wealth Managers, U/HNWI
                and Private Bankers focusing on the new Digital Asset Management
                Technologies."
              </p>
              <p>
                Dr. H.C. Peter Kudera’s management team expressed their
                excitement about the collaboration, saying “We are delighted to
                be working with Coban Capital a firm with a proven track record
                of supporting innovation and growth.”
              </p>
              <p>
                The collaboration will foresee Coban Capital working closely
                with the management team of Dr. H.C Peter Kudera in achieving
                our shared financial vision.
              </p>
              <p>
                About Coban Capital , founded and led by Mr.Mbai in 2020 , is an
                investment management company trading in global financial
                markets. We are focused on our investors interest by producing
                exceptional returns while adhering strictly within a robust risk
                framework, managed by experienced investment professionals.
              </p>
              <p>
                About Dr. H.C. Peter Kudera was born and raised in Vienna,
                Austria. Started his first business in a very early age. He has
                been now into the Blockchain industry for almost 7 years after a
                very interesting incident in Sweden. Today he is a Speaker in
                different International forums, Parliaments and Financial
                Institutions. He believes that the Digital Crypto & Blockchain
                Technology is the solution of Global Financial Crisis and Future
                Market Leader of New Financial World.
              </p>
              <br />
              <br />
              Contact <br />
              <a href="mailto:ir@cobancapital.com"> ir@cobancapital.com</a>
              Investor relations team
              <br />
              Coban Capital Asset Management LLP
              <br />
            </div>
            <Link to="/news-and-insights" className="post__back">
              See all news and insights
            </Link>
          </div>
        </div>
      </div>
      <Footer onClick={executeScroll} />
    </>
  );
};

export default CollaborationAnnouncementPage;
