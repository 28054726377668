import React, { useRef, useState } from "react";
import AppButton from "../../common/AppButton";
import Layout from "../../Components/Layout";

const CareersPage = () => {
  const myRef = useRef(null);

  const POST_DATA = [
    { post: "Network Engineer", location: "London" },
    { post: "Systems Administrator", location: "London" },
    { post: "Computer Programmer", location: "London" },
    { post: "Senior Portfolio manager - Equities", location: "London" },
  ];
  const [singlePost, setSinglePost] = useState({});
  return (
    <Layout>
      <div className="career-page">
        {!singlePost.post ? (
          <>
            {/* here */}
            {/* <div className="page__header-intro">
              <Header />
            </div> */}

            <div className="page__heading" ref={myRef}>
              Careers
            </div>

            <div className="role">
              Our open roles <sup style={{ color: "green" }}>+1</sup>{" "}
            </div>

            {POST_DATA.map((data, index) => (
              <div className="page__position" key={index}>
                <div className="position__content">
                  <div
                    className="content__title"
                    onClick={() => setSinglePost(data)}
                  >
                    {data.post}{" "}
                    {data.post.includes("Senior") && (
                      <sup className="new">New</sup>
                    )}
                  </div>
                  <AppButton
                    title="Read More"
                    onClick={() => setSinglePost(data)}
                  />
                </div>
                <div className="position__location">{data.location}</div>
              </div>
            ))}

            <div className="page__questions">
              <div className="for_further">
                <span>For further questions,</span>
                <a
                  className="email--link"
                  href="mailto:careers@cobancapital.com"
                >
                  <span className="email"> email:</span>{" "}
                  careers@cobancapital.com
                </a>
              </div>
              <br />
              <div
                style={{
                  marginTop: "-20px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Coban Capital is an equal opportunity employer.
              </div>
              {/* <div
                style={{
                  margin: "auto",
                  textAlign: "center",
                  width: "10rem",
                  padding: "1rem 0",
                }}
              >
                <AppButton
                  title="Read More"
                  onClick={() =>
                    setSinglePost({
                      post: " Coban Capital",
                      location: "London",
                    })
                  }
                />
              </div> */}
            </div>
          </>
        ) : (
          <>
            <div className="page__heading">Careers</div>
            <div className="page__single-post">
              <div className="post__heading">
                {singlePost.post}

                <div className="post__location">{singlePost.location}</div>
              </div>
              {singlePost.post.includes("Senior") ? (
                <div className="post__descr">
                  Position : Open
                  <h3>
                    <b>About us</b>
                  </h3>
                  <p>
                    Coban Capital is an investment management company trading in
                    global financial markets. We are focused on our investors{" "}
                    <br />
                    interest by producing exceptional returns while adhering
                    strictly within a robust risk framework, managed by
                    experienced investment professionals.
                  </p>
                  <h4>Role</h4>
                  <p>
                    We are only seeking candidates with Great quantitative
                    portfolio management experience and intimate knowledge of
                    systematic strategies to partner with on our expanding
                    growth which is at a phenomenal rate . <br /> We are seeking
                    for Portfolio Managers who have an outstanding academic and
                    professional track record, with hands on experience running
                    systematic, statistically-driven, scalable trading
                    strategies. <br /> The Portfolio Manager candidates should
                    have experience with all aspects of the research process
                    including methodology selection, data collection and
                    analysis, testing, prototyping, backtesting, and performance{" "}
                    <br />
                    monitoring. The chosen candidate will be responsible for
                    managing a portfolio of financial assets on behalf of the
                    firm.
                  </p>
                  <h3>
                    <b>Qualification </b>
                  </h3>
                  <p>
                    * Have a live track record of at least 2 years, with a PnL
                    of at least +10 million USD managing institutional size
                    capital .
                  </p>
                  <p>
                    * Have experience with fully systematic, strategies with
                    simulated annualised Sharpe of at least 2.5 or greater.
                  </p>
                  <p>
                    * Have strategies trading global equities with a proven
                    track record.
                  </p>
                  <h3>
                    <b>Responsibility </b>
                  </h3>
                  <p>- Develop systematic and or manage equity strategies</p>
                  <p>
                    - Manage own investment portfolio (portfolio will have a
                    separately identifiable track record).
                  </p>
                  <p> - Contribute to firm-wide investment debate.</p>
                  <br />
                  <p style={{ textAlign: "center" }}>
                    ​If you are intrested in being considered for a Portfolio
                    Manager position with us, please email
                    <a
                      className="email--link"
                      href="mailto:pmcandidates@cobancapital.com"
                      style={{ color: "#4299e1" }}
                    >
                      pmcandidates@cobancapital.com
                    </a>
                  </p>
                </div>
              ) : (
                <div className="post__descr">
                  Interested candidates should write an email to{" "}
                  <a
                    className="email--link"
                    href="mailto:careers@cobancapital.com"
                  >
                    careers@cobancapital.com
                  </a>{" "}
                  <br />
                  To be considered appropriately.
                </div>
              )}

              <div className="post__back" onClick={() => setSinglePost({})}>
                See All Jobs
              </div>
            </div>
          </>
        )}
      </div>
      {/* <Footer onClick={executeScroll} /> */}
    </Layout>
  );
};

export default CareersPage;
