import React, { useRef } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer";

const TechnicalPage = () => {
  const myRef = useRef(null);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = () => scrollToRef(myRef);
  return (
    <>
      <div className="contact-page" ref={myRef}>
        <div className="page__header-intro">
          <Header />
        </div>
        <div className="page__heading">Technical Communication</div>
        <div className="page__referrence">
          <div className="referrence__content" style={{ color: "#6b7280" }}>
            If you have sensitive matters to discuss with the team or any other
            conversation where privacy is important, please use the following
            contact information.
            <a className="content__descr" href="mailto: admin@cobancapital.com">
              admin@cobancapital.com
            </a>
          </div>
        </div>
        <div className="page__questions">
          <div className="pgp">
            <p>----BEGIN PGP PUBLIC KEY BLOCK-----</p>
            <p style={{ boxSizing: "border-box" }}>
              xsFNBGN+by8BEACaTXUMbHhvziFnpicXWQkOoS5de6S/Hxmz29DGPV/4x5jfrYnT
              UgFnsIgXySKcjM2+4xZYZMrFALQlnQ5YEpAcMoWlY2c3fNMK7F1DC7ojR+mNGIIQ
              wXuMHbuVJbZFC/z8a39Tfd7+n+u1Eo5bBFvWS14qUE8dGj9nJV41fbiNNpoVaN2c
              Awbrhx291TgBWxxbcKzaWN0/HzPhfq0X2Gs9ZwHzj+GL1fMQeYQLRgasODqqjbad
              YOCAKZvTdG0kj3CJmfNHZMRAQOascJjXL3E86CiRa3Gt623BX1vjnh93DSd84wxY
              HMDG2YduBc6r+9QjsfaBi6H63lgJK9cA1sgZtk4uWc7UQy6nkD3YOdneFjD0RIff
              S2dV2KYSSCefWoEVMSjWtlsN+Wxgs3XGI4ymxlE68dX3Hl1Bbh25Q8C/D6Jh3RKj
              k4G73pC9qRC3vBeL5IFEJXYr9gxwvqt+ck5ygraU7WXk55nuFM/1CRvjbOYBGLN5
              FBn3/5udVQIzx9iNmEatNGrzYFhoZeltpuKohnHr2z1s3pbT50y9DB9ca1Ux0snv
              ATq9P2eQH0+DplrWJyvedpXGLOp2gml9bt3g1tDMzp4EMzsE9JNI9+K0uVwVg9BI
              I80Jxxs+2WqYwi0RrJT9cgZ3d2IrnEbSyFO9yIhBNb83k+4zIluEdZRh+wARAQAB
              zR5BZG1pbiA8YWRtaW5AY29iYW5jYXBpdGFsLmNvbT7CwXoEEwEKACQFAmN+by8C
              Gy8DCwkHAxUKCAIeAQIXgAMWAgECGQEFCQAAAAAACgkQBpypVndbe7sFgw/8Cogf
              NNvAaXzS7ebzN7lq27qfxT4UzfZMQV9QTXdrRzPZ5VRWjH67lGf2F+O4qTBXn0k0
              PSMqbEJLy17WRXn4jlmqNitJ8S+R0crQoESXgFoARQ4YNRDU7LNs78ggQjqDaK7R
              2U3+fflSclAO38ufVE94eF3Oq0ZsegHVH6s+O4wDYmdrf5lJxs594S6HcRKhLNjJ
              eBOFNfZUoVNzVk88nTT0YHIeJ6IWhplgMaEhgpoCZCH2Cz6TYalog9jR1mzAE4p6
              HdOqPhySMXR5IAXooZaiGdpRHZ8PJNQPBvhG8TgBe7pNC9CROYfxPHGm2enOvNzQ
              656SjI0ZX65DL4HNxhNiiXnEYDXnIKLM3CHKOnOA4aIfTBGtc0K7mscBXC5UvFPV
              FbhhJ9ICrd1vRlIBV52xiCpXrlKC5E7o5ViCNAn03qdQmausiWuYTM2EjrRMSOa/
              7PPi9wvu+DGvZbekSkjP9xD3USQcDS2FhT9yXkoHPC9KF5WtIVisTQxTpJpslUE1
              +560hQxlv6MMraBu/jaOmQM+Pu5K8IlkZPaY2F2f21p9U6KXwSEap75LI+hGaZXC
              4epa04Hnnw0373j6HLk+61YcIUycwlphAJZ7bSLe+hjhp/9y7I89PbNcWOd8xfRf
              sAaHlwChsLohUgwlYjZcn6y3kdW5Mx6dvmu1g33OwU0EY35vLwEQAN9QOZ6QO7Ce
              MD6nrPbkMMBw9leX4cWyuevOKyEK1SesqAfG45w4XlqK0RWz3YmKXWEBifpgXBK+
              fXLMKSNQVi3WAPalL0J2DuuThZkiCEYRltBoPXyFDPsLlDVKxvLgPAQCt6JkD/Bo
              xL0H1jcvOl7v8yjFCW+8rSQBQD2FjEdbx4v/WzNDI1L8lDsVl+0gLyymOk+rb3mg
              XjpG0benyKabLXqkrsGQbPpcjm8uxX5pAiC6cGV8E8WLpf6i4wUhfHRhVoeMFsfQ
              k106u9g5R9cCc741tZwLyMmrAyFjeSsFcw53s93APbrMUiiFI/gkLBn7kOFiP9+C
              PqWsNu7bAkj6TnHoBgobADQaj1cb35rA6BJHR7dtR2JSaB545EbqGmmDSHGOsNR/
              rjdeVZ9NtDDJ8+SlUVn1jJsgS0lsicCGQGksoLldFtv0iiFgtASOqDg62Tue1VWe
              RgkVp3QjJwnf+U2pj6BrmkXZmXQQt96lZnW2YJ1d+qH7XlQ+b0fNLwxa1+bXY2Ci
              Cbsxp/5QQN41PWaLp4pSFm3bu3G3UEN18clpbbjTk8jMnBXQA6GazVlZWg7IHc1O
              Dl1RDIn0R2ChBQ0ZMbBFHwR76/7LDSJ65MA/OA9v24YtdySERCSlzjlUj9sgxKFv
              KXBC5oCMisn2N6jRgyXQ0VauCP0G6ftjABEBAAHCw4QEGAEKAA8FAmN+by8FCQAA
              AAACGy4CKQkQBpypVndbe7vBXSAEGQEKAAYFAmN+by8ACgkQp8EC2Iiq8JXmphAA
              o5A96QiAEW5CC0e/7VfZR0iOYSloT+Ms0hJYbL8ywjtAOpqwtTMmmtpOnKUtq+Vh
              frFrj36VHbIQBzxqRyakT3f/zBmYdb8vSpSBzShR4ekVhEUybCkl/EpxR4L4FP39
              ucDumDcd71xq3/wav5TxOd49LpS7uXOjFShpzi9mWbBFK4l/5h6Z8lKmcTqy0/eX
              ThGg8z0HlA0VFBc+Rp3sNYwiOatS9xTb1cydNpjiijUDIGZwXgXXfsmAYxx3LATq
              Qhbuotr/YTZxkHFdAlKWZfkDxkNAABrUuBSjXm69OxTI6dghe8Uua0owjL4RbnEg
              A9uNU25MUXbcxyZcihqUS6qcRsKGA+fANIgJEWUA0V/8FLVOrzUsGNwlmuxfY52G
              kjG8P7j092I0AWCqLc2OM74IwBNHexV1jQgIr4XUyE26deh6JdoRlhiL5/FClWJo
              8ztuyqmDXEPjC2Z1mNegL1ukBK6iIcv4K8LQeecpbMdFhDGVfJpiF4bhEO78toLF
              vMrhW5bmv2PZ12UKztoRn4U0mtgiJrlYYobQr9v9lMAD6nCLhb2gfCDYUrBPJW+5
              B2OzrwryYPimpuLi+J4+7Y+IdVuQWoT6+v/YwNlLZMan7TPrpPw+CMOmNdJl9P3E
              ZMZc7s7GtJVCQjiRQkSNxuGmR2KsmU9Vnkw06lLCbsS8+hAAjd52q2lzRSCkRGa6
              BnxmOMU7zeTmyAgmR9pmOuJvMNfMrXzYYV5yhpd5TXljJ0y8xuOITkuPv25G9QrR
              998x+2caWP30SEltYCZdD8izxMu8N3hGqIF4OP/LZ2aW808UIhPTbiKvVvCyUInC
              jRgoGhziUgcI2kl7HuYcgti1uSJpUdzmkurDsvQZqz90iW895nIngk2B/zyH737v
              +qmxPAo6NYWIwCaua3kcz7oiBxITFav3te3pVK/DkIyC92A301s7rNWwCaBA3j8c
              dUBRuNPGghP5dHssp/KFusCkXZ2BuPmy2Yk6xKd2dXi2vKFmjTIU1C1v0sXpaEDv
              FJKskRrmulStvE3Dvs1TvpGXepxC4S0ElIFdzjm2wsTfxjfcTiRIRteoE6J8G0iP
              HrJhT509Ti2c6IykXcGgbMDjpRapvrcZkePo+XyQHMdVTdaHSk/vF96+LVmc7HOe
              dSTzRPX0Rgag8LTwgN/exbcAah/Epg1g16W2uDEdx4JbJsUB72cwpBEVUXE251o4
              Yg6rSqk9GWxMDsEnZMwNPiJVfo8bsCzrxJ1KPz5MQKLBJcWeyf2MQLwLiqYVIuJU
              Yz5GyNPMjbvhLLJ/3zDX6tUbhWd08bVQmvulrAXofiAH0JhhNlNTaB9JxbnNHAbw
              bA44AG4ITuQFbjE5JSjN116EyebOwU0EY35vLwEQAMflC7EGcdwk4QrVeVg46c2g
              aqKU/iVMy0wSxmTOETfqjaE0B6ogOJ8NX//b4vJmSogNTqjeR7PdU1Il/MPSowsG
              R4qobmmNgGr6h2zngdKrKWp5IuYjLG58x/7ABj02SVqQcpiINO4aQXJBQPqE96hF
              1Xp3mEfcn1NkfisFONZzaiUE1MeD+W3ADKGkGiOEwznKAIt5KjhYNg/RnihzpIrZ
              iG7vAsh8EXo5zclsYmfR+1lUGBp8yRwxWqWSiA/+jVF9nINXBW08k8ENna9wx77B
              ElLscC6SwZsRQ1zVfDKWfqbl+wwxpVFyP277CDPEBJRRp+nSkt5uqPeGUcuLBcvt
              35LmCTT5X6tYR1B5Lau4tg2izOb23VkDq/Qnj/Z1Ysf679Uma62YY4r+nnzZwvrF
              zd0e5G0/vUSYDvVyT0/XqSqPY5Whdd9SBZMC4BTPvF5xpzemGFcpQGeIcPPwf7XY
              pCT1MQ35VmFiRQfudPoSz5hGlcQ4dB9QCHF4576j7QcgyWH6E98rO91JD1HJvixB
              QBC/nOSL7JCHAQ6H/VGWbc4G9sK/RaemmOwAhNpO6mMOyTVbUpfSKERwWcarAF0W
              cQ0Stt/f8nYxnTw1M6Q83vT1d/6HILFm/3IWYc7L4TNGgboLb3aSsxAjewxT4U2N
              00CBbxMa+44eA6Sp92k1ABEBAAHCw4QEGAEKAA8FAmN+by8FCQAAAAACGy4CKQkQ
              BpypVndbe7vBXSAEGQEKAAYFAmN+by8ACgkQQAvJH8nUmkxEUg/+KDYCjKV6Yj5F
              GMq0WGtQOudly78+D0xlyJUuUjdf5asnpN/L13BywpfS80EiPKokex3JEdRCE+tX
              a9sR3Go13/8bX4S824Xxy5/zrUuJugLRLouj6CzAqICbFr+LyIzPpbOlcr6wx20z
              tOSz8JBrRgvQbFpdyjr2J+X7+Uh16w5q+khcixB2qMDoYFcskmd3ynwWVk+UB15+
              kp9jOcqmEuCctxSWdnbW3JL0/QUhJieCGFBEEv71fGPk39dYZuTAn4onoHl6E2cU
              8iCf2qRTzfJb6FV6JE4oGiYBrI/6xFyFwdKSRFK41j1XrbRI1bglDhEEyheRgFki
              7Khybq+b+sJE3xkQqMJ3JiveMn7SL4hZMVc8wId6ykRDcpkIu88+X29oU83ts99H
              lIqFUFDnEPrkLeq79HOKZmqAUHK5DVUYkxG0R2KzHrAquuCNFBup1Ta0825wOWIi
              8Y0n0uDAAMUvbirYZ+EuNA2dLBDcJXL19DzGjDN2PkOHyBpmCYTMPy0POGMw4WvN
              Ce7eIO75pX/lHgbvtBiJ10nTd9cY8OYcrzOBg9/Gb61zMWozHhTvLR92cKNiY7uV
              o23Q440DFehUVjx8JL+ezdAWEcYrh5k+6I/CbpO4n2LVOYwh5dRNYXjxuEeYA1RT
              XCp2kxrMDYufVUoezE2lEML/0nyoepS7lg/5AQXHd2DmN0b+Uyn+woo98egvqFLI
              jHQ1x1M4loZMlfH9uqlyXEAzS6sLgdIS6WpxVs/Lb2t9Jk3j84UWeLuLpytP2dD8
              BIfdCZHWyfwmwZ9D1uTpjh2z4DHgA0pAnBZ/GSuF5wVnxJouqt6og9lJnNiMvcMe
              d57zzcxJJF5Odr0vOs24YNLJz9/E16pa/G/JfpWpnL5yJPWj4Tg5D+AtTy/cIUKW
              g9m/8aRW+b48qfABhbd1RTogw/TQMZadEixTZojdQxX3sWxqUpZuUjNsjXJHn3Fv
              oW5qzPc4amHGjc2zWFuCp1lbAdbaNWwNfUo65ppeb7ik45NRrOCu/3HHwzU1Rn2B
              Itu8zSQ5X3XdgloWeMna1CC/FeZLBBlwjPcYy/lJpMtfmypBXiOUaM44W8ENrb4f
              5Q9lcm0r6OEdaOH5vzSexEFrSFfHQK6yDzfZMI0URH2JMTrXqawXHWS7LPawUYc0
              rt4Nk8K0tJ1uW/G4QgD4yZX1b3YJtZ6k/jmJbS8+yJlZJsyWEfqGlY1NZhi20xTr
              9pmqxJkTs8UTO4QI37m5F+WnSqc5hJkbPrb/K+k56bnpn/B7rME6t4VHzF53kSbR
              KFqeiKA+CoeflJ9mtjieslLjF3DhJQ6Dl00VgEWKEEHn5x3zh6RXQODepJ/MZukJ
              Ca2fK5o81qrXxmk==agWa
            </p>

            <p>-----END PGP PUBLIC KEY BLOCK-----</p>
          </div>
        </div>
        {/* <div className="page__questions">
          For regulatory disclosures, please kindly write us using the General
          Inqueries details above.
        </div> */}
      </div>
      <Footer onClick={executeScroll} />
    </>
  );
};

export default TechnicalPage;
