import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout";

const Disclosure = () => {
  return (
    <Layout>
      <div className="site-policy">
        <div className="policy__heading">Disclosures</div>
        <div className="policy__description" style={{ paddingTop: "2rem" }}>
          <b>Coban Capital Asset Management LLP and Coban Global Limited</b>
          {/* <p>
            <Link to="/stewardship-code" className="text-decoration-none">
              UK Stewardship Code
            </Link>
          </p> */}
          <p>
            <Link to="/tax-strategy" className="text-decoration-none">
              UK Tax Strategy
            </Link>
          </p>
          <p>
            <Link to="/modern-slavery-act" className="text-decoration-none">
              Modern slavery act
            </Link>
          </p>

          <p>
            Investments in these programs involve a substantial amount of risk,
            including loss of capital. This website is only intended for general
            guidance only and it does not constitute an offer to sell nor a
            solicitation of an offer to buy interests described herein.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Disclosure;
