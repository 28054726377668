import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const Menu = ({ active, setIsOpenMenu }) => {
  const { pathname } = useLocation();
  return (
    <div className={`${active ? "" : "trans-menu"} menu `}>
      <div className="menu-items">
        <div className="menu-buttons">
          <Link
            to="/"
            onClick={() => setIsOpenMenu(false)}
            className={
              pathname === "/" ? `page-links border-bottom` : `page-links`
            }
          >
            Home
          </Link>

          <Link
            to="/career"
            onClick={() => setIsOpenMenu(false)}
            className={
              pathname === "/career" ? `page-links border-bottom` : `page-links`
            }
          >
            Careers
          </Link>

          <Link
            to="/sustainability"
            className={
              pathname === "/sustainability"
                ? `page-links border-bottom`
                : `page-links`
            }
          >
            Sustainability
          </Link>
          <Link
            to="/news-and-insights"
            className={
              pathname === "/sustainability"
                ? `page-links border-bottom`
                : `page-links`
            }
          >
            News and Insights <sup className="new">New</sup>
          </Link>
          <div
            className={pathname === "/contact-us" ? `page-links` : `page-links`}
          >
            <Link
              to="/contact-us?tab=contact"
              className="contact-us"
              onClick={() => setIsOpenMenu(false)}
            >
              Contact Us
            </Link>
            <span className="arrow">
              <MdOutlineKeyboardArrowDown />
            </span>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/contact-us?tab=contact"
                  className="submenu-link"
                  onClick={() => setIsOpenMenu(false)}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us?tab=prospective-client"
                  className="submenu-link"
                  onClick={() => setIsOpenMenu(false)}
                >
                  Prospective Client
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
