import React from "react";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="site-policy">
        <div className="policy__heading">Privacy policy </div>
        <div className="policy__sub-heading">Updated October 22,2022</div>
        <div className="policy__description">
          Thank you for visiting the Coban Capital Asset Management LLP and
          Coban Global Limited (together, “Coban Capital,” or “we” or “us”)
          family of website (“Coban Capital ”).
        </div>
        <div className="policy__description">
          These policies only apply to individuals and are subject to change. If
          you are located in the EU (or are a vendor, client or prospect of a
          Coban Capital affiliate located in the EU), Coban Capital is the data
          controller of your personal information.{" "}
        </div>
        <div className="policy__description">
          Please note that when using the website, this privacy policy should be
          read in conjunction with our Terms of Use. Coban Capital RESPECTS YOUR
          PRIVACY. THIS PRIVACY POLICY DISCLOSES HOW WE COLLECT, USE, SHARE, AND
          PROTECT INFORMATION GATHERED FROM PARTICIPATING Coban Capital SITES
          FOR RECRUITING PURPOSES.{" "}
        </div>
        <div className="policy__description">
          BY USING THE COBAN CAPITAL SITES OR INTERACTING WITH US ONLINE, YOU
          ARE AGREEING TO THE TERMS OF THIS PRIVACY POLICY, AS POSTED HERE. IF
          YOU DO NOT AGREE WITH ANY TERMS OR PRACTICES DESCRIBED IN THIS PRIVACY
          POLICY, PLEASE DO NOT ACCESS, USE OR VISIT THR COBAN CAPITAL SITE. IN
          ADDITION, PLEASE REVIEW OUR TERMS OF USE , WHICH GOVERNS YOUR USE OF
          OUR WEBSITES AND ANY DISPUTE ABOUT PRIVACY.
        </div>
        <div className="policy__sub-heading">
          Collection of personal information
        </div>

        <div className="policy__description">
          Coban Capital may ask for and collect personal information from you
          and other information, including personal data, at certain points via
          this website and in the course of your relationship and interactions
          with us. Depending on the information and/or services you request, you
          may be asked to provide your name, email address and other information
          including identification documents. In addition to the information you
          knowingly provide, Coban Capital collects the domain names and IP
          addresses of its website visitors, along with usage statistics (e.g.,
          types of web browsers and operating systems used) and browsing
          history.{" "}
        </div>
        <div className="policy__description">
          Moreover, when you use our website or otherwise engage in
          communication with us including, for example, submitting an employment
          application, or when you (or any entity you represent) enter into a
          contractual relationship with us, including, for example, as an
          employee or service provider.
        </div>
        <div className="policy__description">
          We collect information about you from a variety of sources, which may
          include:
        </div>
        <div className="policy__description">
          any documents submitted to us, including any application forms;
        </div>
        <div className="policy__description">
          our communication or correspondence with you, such as when you contact
          us by letter,
        </div>
        <div className="policy__description">
          telephone, email or any other means of electronic or personal
          communication;
          <br />
          your use of our website (including by use of cookies; and our terms of
          use, please visit Terms of Use{" "}
          <Link to="/terms-use" className="policy__link">
            here
          </Link>{" "}
          );
        </div>
        <div className="policy__description">
          This data may be used or promote our services. For such purpose, Coban
          Capital may combine this information with the personally identifiable
          and other information it collects.
        </div>

        <div className="policy__sub-heading">Use of data collected </div>
        <div className="policy__description">
          We may use the Personal Data that we collect about you for any or all
          of the following purposes:
        </div>
        <div className="policy__description">
          To respond to your requests or inquiries.
        </div>
        <div className="policy__description"></div>
        <div className="policy__description">
          To communicate with or inform you regarding Coban Capital services
          that may be available to you;
        </div>
        <div className="policy__description">
          To customize the Site for you by, for example, not having to enter
          information like your address more than once or to help you quickly
          find information that is relevant to you based on your interests;
        </div>
        <div className="policy__description">
          For Coban Capital’s business purposes, such as to help us explore ways
          to improve our services, to help us improve the website, or to help us
          create Site content areas most relevant to you, including by
          requesting feedback, and for related internal purposes such as data
          analysis, legal analysis, audits, enhancing the Site, and identifying
          usage trends;
        </div>
        <div className="policy__description">
          To help us operate and improve the Site, such as by analyzing Site
          visitor trends, including usage of certain pages or functions on the
          Site;
        </div>
        <div className="policy__description">
          To protect against fraud, spam, harassment, intellectual property
          infringement, crime and security risks;{" "}
        </div>
        <div className="policy__description">
          To maintain and improve the safety and security of the Site.
        </div>

        <div className="policy__sub-heading">
          Sharing your personal information
        </div>
        <div className="policy__description">
          Coban Capital generally does not share non-public personal information
          with unaffiliated third parties, except as necessary to perform
          certain duties such as:
        </div>
        <div className="policy__description">
          {" "}
          Third party service providers in order to perform business or
          technical support functions to Coban Capital ;
        </div>
        <div className="policy__description">
          {" "}
          In response to a judicial process or regulatory inquiry, or as may be
          otherwise required by law;
        </div>
        <div className="policy__description">
          {" "}
          As necessary with your express consent;{" "}
        </div>
        <div className="policy__description">
          {" "}
          As otherwise set forth at the point of collection.
        </div>
        <div className="policy__description">
          Coban Capital may share aggregated or anonymous non-personal
          information with third parties for their marketing or analytics uses. 
          It is the Firm’s policy to comply with the privacy legislation within
          each jurisdiction in which the Firm operates and Internationally. 
        </div>

        <div className="policy__sub-heading">Transfer of information.</div>
        <div className="policy__description">
          Your information may be shared with persons outside of your home
          country since we use cloud-based technologies and operate in a global
          economy (which may be the UK, EEA or the DIFC). If this is the case,
          we make certain that adequate security is in place to protect your
          data.{" "}
        </div>
        <div className="policy__description">
          Due to the worldwide nature of our business, your information may be
          transmitted to jurisdictions outside the UK, EEA, or DIFC. Such
          jurisdictions may not provide the same level of data protection as
          your home jurisdiction, and the European Commission or other
          applicable regulators may not consider them to be providing an
          adequate level of data protection.{" "}
        </div>
        <div className="policy__description">
          When we move your information outside of your home country, we will
          ensure that personal data is protected and transferred in accordance
          with applicable legal requirements, which can be done in the following
          ways:
        </div>
        <div className="policy__description">
          The recipient may have signed a contract based on standard contractual
          clauses approved by the European Commission or the DIFC Commissioner
          of Data Protection, obliging them to protect your personal
          information;
        </div>

        <div className="policy__sub-heading">Collection of information.</div>
        <div className="policy__description">
          Coban Capital, along with third parties or other sources, may collect
          and store technical and navigation information, such as device type,
          browser type, Internet protocol address, pages visited, and the
          average time spent on our website. We use this information for a
          variety of purposes, such as maintaining the security of both our
          website and your personal information, facilitating site navigation,
          and improving website design and functionality.
        </div>

        <div className="policy__sub-heading">Security of information.</div>
        <div className="policy__description">
          Non-public personal information is not shared with unaffiliated third
          parties unless it is necessary to perform certain functions, such as:
          Third-party service providers performing business or technical support
          functions;{" "}
        </div>
        <div className="policy__description">
          In response to a judicial process or regulatory inquiry, or as may be
          otherwise required by law;{" "}
        </div>
        <div className="policy__description">
          As necessary with your express consent;{" "}
        </div>
        <div className="policy__description">
          As otherwise set forth at the point of collection.
        </div>
        <div className="policy__description">
          Coban Capital may share non-personal, aggregated or anonymous data
          with third parties for marketing or analytics purposes. The Firm's
          policy is to follow all applicable privacy laws in each jurisdiction
          in which it works, as well as internationally.
        </div>

        <div className="policy__sub-heading">
          Data quality storage and access{" "}
        </div>
        <div className="policy__description">
          We take reasonable organisational, technological, and administrative
          means to assist protect Personal Data obtained through the Site and
          under our control from loss, abuse, and alteration. However, no data
          transmission or storage method over the Internet can be guaranteed to
          be completely safe. Please do not give us any sensitive Personal Data
          over the Site. In addition, Coban Capital expects its partners and
          service providers to effectively maintain the privacy and security of
          personal data they are granted access to.{" "}
        </div>

        <div className="policy__sub-heading">Retention</div>
        <div className="policy__description">
          We take the protection of your personal information seriously, and
          have security measures, controls and policies in place.{" "}
        </div>
        <div className="policy__description">
          {" "}
          We will hold your personal information on our systems for the longest
          of the following periods:
        </div>
        <div className="policy__description">
          As long as is necessary for the relevant activity or as long as is set
          out in any relevant agreement you enter into with us;{" "}
        </div>
        <div className="policy__description">
          The length of time it is reasonable to keep records to demonstrate
          compliance with professional or legal obligations; any retention
          period that is required by law;{" "}
        </div>
        <div className="policy__description">
          The end of the period in which litigation or investigations might
          arise in respect of your use of our website or the services that we
          provide to you.{" "}
        </div>

        <div className="policy__sub-heading">
          Protecting Children’s Privacy Online.
        </div>
        <div className="policy__description">
          Individuals under the age of thirteen are not permitted to use our
          website . If you are the parent or legal guardian of a child under the
          age of 13 and suspect that child has used our website, please inform
          us as soon as possible. Coban Capital does not intentionally collect
          personal information from children under the age of 13 in accordance
          with the Children's Online Privacy Protection Act of 1998.{" "}
        </div>

        <div className="policy__sub-heading">Cookies</div>
        <div className="policy__description">
          When you visit a website, it saves information in the form of cookies
          on your computer's hard disc. Cookies may include the transmission of
          information from us to you and from you to us, as well as to third
          party on our behalf or in compliance with this privacy policy. Cookies
          may also be used to combine information about you that we have
          collected. You can have your computer alert you each time a cookie is
          transmitted, or you can turn off all cookies entirely. You may
          encounter some inconvenience when using our website if you refuse to
          accept cookies or erase cookies.{" "}
        </div>

        <div className="policy__sub-heading">Additional information</div>
        <div className="policy__description">
          Coban Capital reserves the right, at any time and for any reason, to
          update, modify, add, or remove elements of our Privacy Policy. Any
          modifications to this policy will take effect immediately after they
          are posted, without giving you prior notice. The most recent version
          of this Privacy Policy can be found at the bottom of our page via a
          link.{" "}
        </div>

        <div className="policy__sub-heading">Contact us</div>
        <div className="policy__description">
          {" "}
          If you have any questions or comments about our privacy policy or
          would like to access, correct, update or delete your personal
          information, please feel free to contact us at info@cobancapital.com .
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
