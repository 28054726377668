import React, { useRef } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer";
import articleImg from "../../assets/article.jpg";
import articleImg2 from "../../assets/collab.jpg";

const NewsInsightsPage = () => {
  const myRef = useRef(null);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = () => scrollToRef(myRef);
  return (
    <>
      <div className="newsInsight-page" ref={myRef}>
        <div className="page__header-intro">
          <Header />
        </div>
        <div className="page__heading">News and Insights</div>
        <div className="blog__container">
          <div className="blog__filter">
            <span>Filter by</span>
            <div className="blog__filter__btns">
              <button className="active">All</button>
              <button>News</button>
              <button>Insights</button>
            </div>
          </div>
          <div className="blog__articles">
            <a
              href="/news-and-insights/collaboration-announcement"
              className="blog__article"
            >
              <div className="article">
                <div className="article__text">
                  <div className="upper__text">
                    <span className="tag">Press Release</span>
                    <span className="date">June 1, 2023</span>
                  </div>
                  <div className="article__title">
                    Collaboration Announcement
                  </div>
                  <div className="article__desc">
                    In this press release , Coban Capital today announced the
                    collaboration with the management team of Dr. H.C. Peter
                    Kudera. Mr.Mbai, Chairman and CEO of Coban Capital said :
                  </div>
                </div>
                <div className="article__img">
                  <img src={articleImg2} alt="article" />
                </div>
              </div>
            </a>
            <a
              href="/news-and-insights/pri-supported"
              className="blog__article"
            >
              <div className="article">
                <div className="article__text">
                  <div className="upper__text">
                    <span className="tag">Press Release</span>
                    <span className="date"> March 23, 2023</span>{" "}
                  </div>
                  <div className="article__title">
                    Coban Capital Asset Management LLP signs on to UN-Principles
                    for responsible investment
                  </div>
                  <div className="article__desc">
                    In this Press release, Coban Capital today announced its
                    decision to sign on to the United Nations supported
                    principles of Responsible Investment (PRI)
                  </div>
                </div>
                <div className="article__img">
                  <img src={articleImg} alt="article" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <Footer onClick={executeScroll} />
    </>
  );
};

export default NewsInsightsPage;
