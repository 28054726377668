import { createContext, useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./styles/styles.scss";
import AOS from "aos";
import "aos/dist/aos.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import CareersPage from "./Pages/CareersPage";
import ContactUsPage from "./Pages/ContactUs";
import Login from "./Pages/Auth/Login";
import CookiesModal from "./Components/CookiesModal";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import PrivacyPolicy from "./Pages/Auth/PrivacyPolicy";
import CookiesPolicy from "./Pages/Auth/CookiesPolicy";
import TermsUse from "./Pages/Auth/TermsUse";
import Sustainability from "./Pages/Sustainability";
import ScrollToTop from "./common/ScrollToTop";
import Disclaimer from "./Pages/disclaimer";
import Disclosure from "./Pages/Auth/Disclosure";
import TaxStrategy from "./Pages/Auth/TaxStrategy";
import ModernAct from "./Pages/Auth/ModernAct";
import StewardshipCode from "./Pages/Auth/StewardshipCode";
import TechnicalPage from "./Pages/TechnicalPage";
import OfficialVerificaltion from "./Pages/OfficialVerification";
import NewsInsightsPage from "./Pages/NewsInsights";
import PriSupportedPage from "./Pages/NewsInsights/PriSupported";
import ThankYouPage from "./Pages/ThankYouPage";
import CollaborationAnnouncementPage from "./Pages/NewsInsights/CollaborationAnnouncement";

export const ActiveContext = createContext();

const App = () => {
  const [active, setActive] = useState(1);
  const [value, setValue] = useState(20);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <ActiveContext.Provider
      value={{
        active,
        setActive,
        setValue,
        value,
      }}
    >
      <Router>
        <ScrollToTop />
        <div className="app">
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/career" element={<CareersPage />} />
            <Route exact path="/contact-us" element={<ContactUsPage />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/reset-password" element={<ForgotPassword />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/cookies-policy" element={<CookiesPolicy />} />
            <Route exact path="/terms-use" element={<TermsUse />} />
            <Route exact path="/sustainability" element={<Sustainability />} />
            <Route exact path="/disclaimer" element={<Disclaimer />} />
            <Route exact path="/disclosure" element={<Disclosure />} />
            <Route exact path="/tax-strategy" element={<TaxStrategy />} />
            <Route exact path="/modern-slavery-act" element={<ModernAct />} />
            <Route
              exact
              path="/official-verification"
              element={<OfficialVerificaltion />}
            />
            <Route
              exact
              path="/stewardship-code"
              element={<StewardshipCode />}
            />
            <Route exact path="/pgp-communiation" element={<TechnicalPage />} />
            <Route
              exact
              path="/official-verification"
              element={<OfficialVerificaltion />}
            />
            <Route
              exact
              path="/news-and-insights"
              element={<NewsInsightsPage />}
            />
            <Route
              exact
              path="/news-and-insights/pri-supported"
              element={<PriSupportedPage />}
            />
            <Route
              exact
              path="/news-and-insights/collaboration-announcement"
              element={<CollaborationAnnouncementPage />}
            />
            <Route exact path="/thank-you" element={<ThankYouPage />} />
          </Routes>
          <CookiesModal />
        </div>
      </Router>
    </ActiveContext.Provider>
  );
};

export default App;
