import React, { useEffect, useRef } from "react";
import AppButton from "../../common/AppButton";
import logo from "../../assets/SVG/logo.svg";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import { LogoGather } from "../../Components/Logo/Logo";
import { useLocation } from "react-router-dom";
const HomePage = () => {
  const myRef = useRef(null);
  const { hash } = useLocation();
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = () => scrollToRef(myRef);
  useEffect(() => {
    if (hash) {
      executeScroll();
    }
  });

  return (
    <>
      <div className="home-page">
        <div className="page__header-intro">
          <Header />
          <div className="page__intro" key="demo1">
            <div className="intro__content">
              <div className="content__description">
                {" "}
                <span className="description__heading">
                  <span className="text-underline">Vision</span> of alternative
                  investments
                </span>
                <br />
                Where leading innovative financial professionals gather.
              </div>
            </div>

            <LogoGather image={logo} pixSize={15} pointSizeMin={8} />
          </div>
          <div className="hero_info">
            <div className="info__container">
              <div className="info__container_title">15+ yrs</div>
              <div className="info__container_subtitle">
                Average precessional industry experience
              </div>
            </div>
            <div className="info__container">
              <div className="info__container_title">12+ </div>
              <div className="info__container_subtitle">
                Investment teams growth
              </div>
            </div>
            <div className="info__container">
              <div className="info__container_title">3+</div>
              <div className="info__container_subtitle">
                Core Strategies focus
              </div>
            </div>
            <div className="info__container">
              <div className="info__container_title">8000+ </div>
              <div className="info__container_subtitle">
                Exchange listed Equities Universe
              </div>
            </div>
          </div>
        </div>

        <div className="page__characters" key="demo2" id="about" ref={myRef}>
          <div className="characters__heading">About Us</div>
          <div className="characters__description desktop">
            Coban Capital, founded and led by Mr.Mbai in 2020 , is an investment
            management company trading in global financial markets. We are
            focused on our investors interest by producing exceptional returns
            while adhering strictly within a robust risk framework, managed by
            experienced investment professionals.
          </div>
          <div className="characters__description mobile">
            Coban Capital, founded and led by Mr.Mbai in 2020 , is an investment
            management company trading in global financial markets. We are
            focused on our investors <br />
            interest by producing exceptional returns while <br />
            adhering strictly within a robust risk framework, <br />
            managed by experienced investment professionals.
          </div>
        </div>
        <div className={`page__box btn-bg--color-ffffff`} key="demo3">
          <div className="box__content">
            <div className="content__title"> Extraordinary Capability</div>
            <div className="content__descr">
              We differentiate ourselves successfully by offering the best
              environment for our own portfolio managers to excel in their
              investment philosophies.
            </div>
          </div>
        </div>
        <div className="page__box flex justify-content-end " key="demo4">
          <div className="box__content btn-bg--color-ffffff">
            <div className="content__title">Unique Approach</div>
            <div className="content__descr">
              Coban Capital seeks to create a result driven environment ,
              attracting the world’s best professionals and delivering
              purposeful partnerships for our global institutional investors.
            </div>
          </div>
        </div>
        <div className="page__characters btn-bg--color-ffffff" key="demo5">
          <div className="characters__heading">Careers</div>
          <div className="characters__description">
            At Coban Capital, be great at what you do.
            <br />
            Interested candidates should view our open roles.
            <br />
          </div>
          <Link to="/career" className="action">
            <AppButton title="Apply Now" />
          </Link>
        </div>
      </div>
      <Footer onClick={executeScroll} />
    </>
  );
};

export default HomePage;
