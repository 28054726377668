import React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import pdfFile from "../assets/Disclaimer.pdf";
function Disclaimer() {
  return (
    <div>
      <Document file={pdfFile}>
        <Page pageNumber={1} style={{ background: "tomato" }} />
      </Document>
      {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
    </div>
  );
}

export default Disclaimer;
