/* eslint-disable no-undef */
import React, { useRef } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer";

const ThankYouPage = () => {
  const myRef = useRef(null);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = () => scrollToRef(myRef);

  return (
    <>
      <div className="contact-page" ref={myRef}>
        <div className="page__header-intro">
          <Header />
        </div>
        <div className="page__heading">Contact Us</div>
        <div className="thank_you">
          Thank you , your inquiry has been submitted
        </div>
      </div>
      <Footer onClick={executeScroll} />
    </>
  );
};

export default ThankYouPage;
