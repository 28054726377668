import React, { useRef } from "react";

import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer";

const Esg = () => {
  const myRef = useRef(null);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = () => scrollToRef(myRef);
  return (
    <>
      {" "}
      <div className="esg-page" ref={myRef}>
        <div className="page__header-intro">
          <Header />
        </div>

        <div className="esg__container" key="demo2">
          <div className="esg__heading">Environmental. Social. Governance.</div>
        </div>
        <div className="page__characters">
          <div className="characters__description desktop">
            Coban Capital is dedicated to providing our clients with innovative,
            durable, and scalable solutions that ensure
            <br />
            long-term success.
          </div>
          <div className="characters__description mobile">
            Coban Capital is dedicated to providing our clients
            <br />
            with innovative, durable, and scalable <br />
            solutions that ensure long-term success.
          </div>
        </div>
        <div className={`page__box btn-bg--color-ffffff`} key="demo3">
          <div className="box__content">
            <div className="content__title">Responsible Investing</div>
            <div className="content__descr">
              While there is no one-size-fits-all strategy to ESG investment,
              there are some guidelines to follow to meet the ever changing
              advances, while positioning our clients for success in the long
              run.
            </div>
          </div>
        </div>
        <div className="page__box flex justify-content-end " key="demo4">
          <div className="box__content btn-bg--color-ffffff">
            <div className="content__title">Asset Class Intergration</div>
            <div className="content__descr">
              We deliver investment solutions to our clients' ESG and
              sustainability needs by bringing significant investing experience
              across asset classes.
            </div>
          </div>
        </div>
        <div className="page__characters btn-bg--color-ffffff" key="demo5">
          {/* <div className="characters__heading">Careers</div> */}
          <div className="characters__description desktop">
            As our firm commitment to sustainability, we leverage our
            capabilities for our clients to make a global impact by providing
            <br />
            solutions focusing on an inclusive green economy.
          </div>
          <div className="characters__description mobile">
            As our firm commitment to sustainability, we
            <br />
            leverage our capabilities for our clients to make a
            <br />
            global impact by providing solutions
            <br /> focusing on an inclusive green economy.
          </div>
        </div>
      </div>
      <Footer onClick={executeScroll} />
    </>
  );
};

export default Esg;
