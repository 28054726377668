import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout";

const StewardshipCode = () => {
  return (
    <Layout>
      <div className="site-policy">
        <div className="policy__heading">MODERN SLAVERY ACT</div>
        <div className="policy__description" style={{ padding: "2rem" }}>
          <p>
            In Compliance to Rule 2.2.3R of the Financial Conduct Authority’s
            Conduct of Business Sourcebook, Coban Capital Asset management LLP
            is making this statement whether it commits to the UK Financial
            Reporting Council's Stewardship Code (the "Stewardship Code") or
            explain why it does not, having consideration to its investment
            approach.The Conduct of Business Sourcebook , as a disclosure of
            commitment , requires authorised firms :“... must disclose clearly
            on its website, or if it does not have a website in another
            accessible form:
          </p>
          <p>
            * (1)  the nature of its commitment to the Financial Reporting
            Council’s Stewardship Code; or
          </p>
          <p>
            * (2)  where it does not commit to the Code, its alternative
            investment strategy.”Coban Capital Asset Management LLP acts as a
            sub-advisor within the broader structure of the Coban Capital Group.
            As such , The Coban Capital Group , manages multiple investment
            strategies in various asset classes including assets other than UK
            listed equities or fixed income assets.In the limited cases of Coban
            Capital Asset Management LLP interaction with UK investee companies
            , it achieves this in accordance with various firm policies designed
            to act in the best interest of its investors. In perspective, while
            Coban Capital Asset Management LLP generally is supportive of the
            Stewardship Code and its principles thereof, it has chosen not to
            commit to the Code.
          </p>
          <p>
            Coban Capital Asset Management LLP acts as a sub-advisor within the
            broader structure of the Coban Capital Group. As such , The Coban
            Capital Group , manages multiple investment strategies in various
            asset classes including assets other than UK listed equities or
            fixed income assets.In the limited cases of Coban Capital Asset
            Management LLP interaction with UK investee companies , it achieves
            this in accordance with various firm policies designed to act in the
            best interest of its investors.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default StewardshipCode;
