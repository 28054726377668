import React, { useRef } from "react";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer";
import articleImg from "../../../assets/article.jpg";
import { Link } from "react-router-dom";

const PriSupportedPage = () => {
  const myRef = useRef(null);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = () => scrollToRef(myRef);
  return (
    <>
      <div className="newsInsight-page" ref={myRef}>
        <div className="page__header-intro">
          <Header />
        </div>
        <div className="page__heading">News and Insights</div>
        <div className="blog__container">
          <div className="blog__filter"></div>

          <div className="post">
            <div className="post__title">
              In this press release , Coban Capital today announced its decision
              to sign on to the United Nations supported principles of
              Responsible Investment (PRI).
            </div>
            <div className="post__img">
              <img src={articleImg} alt="article" />
            </div>
            <div className="post__desc">
              <p>
                Coban Capital today announced its decision to sign on to the
                United Nations supported principles of Responsible Investment
                (PRI).
              </p>
              <p>
                The PRI is recognized as the leading global network for
                investors who are committed to integrating environmental, social
                and governance (ESG) considerations into their investment
                practices and ownership policies. In implementing the
                principles, signatories contribute to the development of a more
                sustainable global financial system.
              </p>
              <p>
                The Principles for Responsible Investments, voluntary and
                aspirational, aim to further understanding of the implications
                of sustainable investing and support signatories to incorporate
                ESG issues into their decision making and ownership practices.
              </p>
              <p>
                Over the last year, the number of PRI signatories has increased
                to more than 5,000 signatories globally, representing more than
                USD 121 tln in assets under management.
              </p>
              <p>
                We’re pleased to welcome Coban Capital as a signatory to the
                PRI,” said David Atkin, CEO at the Principles for Responsible
                Investment. “Now, more than ever, it is vital for responsible
                investors to come together to work towards common goals, for the
                benefit of both people and planet. We look forward to working
                alongside Coban Capital to realise these goals.”
              </p>
              <p>
                Coban Capital’s commitment to the PRI will increase the
                Company's visibility to institutional investors and provide an
                opportunity to evaluate the progress in ‘responsible investment’
                based on the industry standards.
              </p>
              <p>
                We do not pursue an ESG-based investment strategy or limit our
                investments to those that meet specific ESG criteria or
                standards. For more information on Coban Capital, please kindly
                visit : https//www.cobancapital.com
              </p>
              <br />
              <br />
              Contact <br />
              <a href="mailto:ir@cobancapital.com"> ir@cobancapital.com</a>
              Investor relations team
              <br />
              Coban Capital Asset Management LLP
              <br />
            </div>
            <Link to="/news-and-insights" className="post__back">
              See all news and insights
            </Link>
          </div>
        </div>
      </div>
      <Footer onClick={executeScroll} />
    </>
  );
};

export default PriSupportedPage;
