import React, { useState } from "react";
import { Link } from "react-router-dom";
import AppButton from "../../common/AppButton";
import Layout from "../../Components/Layout";

const Login = () => {
  const [valid, setValid] = useState(false);
  const toggleValid = () => {
    setValid(true);
  };
  return (
    <Layout>
      <div className="login-page">
        <div className="page__heading">Investors Login</div>
        <div className="page__box">
          {valid && <p className="error">Invalid login</p>}
          <div className="box__label">Email :</div>
          <input type="email" onChange={() => toggleValid()} />
          <div className="box__label">Password :</div>
          <input type="password" onChange={() => toggleValid()} />
          <Link to="/reset-password" className="box__forgot">
            Forgot Password
          </Link>
          <AppButton title="Login" onClick={() => toggleValid()} />
          <p style={{ textAlign: "center", padding: "1rem 0" }}>
            {" "}
            <i>This website is for professional clients only. </i>{" "}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
