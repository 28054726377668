import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/SVG/logo.svg";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer";
import { useLocation } from "react-router-dom";
const OfficialVerificaltion = () => {
  const myRef = useRef(null);
  const [verifyInput, setverifyInput] = useState("");
  const { hash } = useLocation();
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const [isVerified, setIsVerified] = useState(undefined);

  const executeScroll = () => scrollToRef(myRef);
  const verifiedData = [
    "https://www.cobancapital.com",
    "info@cobancapital.com",
    "client@cobancapital.com",
    "ir@cobancapital.com",
    "press@cobancapital.com",
    "careers@cobancapital.com",
    "admin@cobancapital.com",
    "arnold@cobancapital.com",
    "12852431",
    "oc443495",
  ];
  const onChangeInput = (e) => {
    setverifyInput(e.target.value);
  };
  const handleVerification = () => {
    const verification = verifiedData.find(
      (v) => v === verifyInput.toLowerCase()
    );
    if (verification) {
      setIsVerified(true);
    } else setIsVerified(false);
  };
  useEffect(() => {
    if (hash) {
      executeScroll();
    }
  });
  return (
    <>
      <div className="home-page">
        <div className="page__header-intro">
          <Header />
        </div>

        <div className="verify__container" key="demo2" id="about" ref={myRef}>
          <div className="heading">
            <img
              className="svg_logo"
              src={logo}
              width={45}
              height={45}
              alt="coban capital"
            />{" "}
            Coban Capital Verify
          </div>
          <div className="confirm">
            <span>Please confirm that you are visiting : </span>

            <a
              href="https://www.cobancapital.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.cobancapital.com
            </a>
          </div>
          <div className="verify-input-container">
            <input
              className="verify-input"
              placeholder="Enter inquiry"
              type="text"
              name="verify"
              id="verify"
              onChange={onChangeInput}
            />
            <button
              type="button"
              className="search"
              onClick={handleVerification}
            >
              Search
            </button>
          </div>
          <div className="warning">
            Please use Coban Capital Verify to check whether the source
            officially represents Coban Capital.{" "}
            <strong>
              Website ,email ,phone ,company number ,document ID etc.
            </strong>
          </div>
          <div className="warning">
            *Please do not contact any unofficial/non-verified sources . For
            further inquiries please{" "}
            <a className="contact_us" href="mailto:info@cobancapital.com">
              contact us.
            </a>
          </div>
          <div className="popup">
            {isVerified && (
              <div className="popup-verified">
                <div>
                  The source you entered is an official domain from Coban
                  Capital.{" "}
                </div>
              </div>
            )}
            {isVerified === false && (
              <div className="popup-not-verified">
                <div>
                  The source you entered is not verified and is not official
                  Coban Capital domain . Please exercise caution and only
                  interact with verified official sources.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer onClick={executeScroll} />
    </>
  );
};

export default OfficialVerificaltion;
