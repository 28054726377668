import React from "react";
import Layout from "../../Components/Layout";

const ModernAct = () => {
  return (
    <Layout>
      <div className="site-policy">
        <div className="policy__heading">Modern Slavery Act</div>
        <div
          className="policy__description"
          style={{ padding: "3rem", textAlign: "center" }}
        >
          <p>
            This statement is made pursuant to section 54(1) of the Modern
            Slavery Act 2015 (the “Act”) in respect of Coban Capital Asset
            Management LLP and its affiliate, Coban Global Limited
            (collectively, “Coban Capital” or “we”) and constitutes our modern
            slavery statement. Coban Capital Asset Management LLP is a limited
            liability partnership incorporated in England and Wales. Coban
            Global Limited is a limited company incorporated in England and
            Wales.Examples of human rights frameworks and principles that we
            accept and defend are the International Labor Organization
            Declaration on Fundamental Principles and Rights at Work and the
            United Nations Guiding Principles on Business and Human Rights.This
            statement was approved by the Members of Coban Capital Asset
            Management LLP and the Managing Director of Coban Global Limited in
            August 2022.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ModernAct;
