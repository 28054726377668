import React from 'react'
import AppButton from '../../common/AppButton'
import Layout from '../../Components/Layout'

const ForgotPassword = () => {
    return (
        <Layout>

            <div className='login-page'>
                <div className='page__heading'>
                    Reset Password
                </div>
                <div className='page__box' >
                    <div className='box__label'>Email :</div>
                    <input className='mb--20' type='email' />
                    <AppButton title='Reset' />
                </div>

            </div>
        </Layout>

    )
}

export default ForgotPassword